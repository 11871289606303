import { FC, useState } from 'react'
import { Box, Flex, IconButton } from '@chakra-ui/react'

import { CbDateRangePicker, DateRange } from '../CbDateRangePicker'
import { CbSelect, Option } from '../CbSelect'
import Calendar from '../Icons/Calendar'
import Close from '../Icons/Close'
import FilterOutline from '../Icons/FilterOutline'

export interface CbMobileFilterDateRangeProps {
  contentBackgroundColor?: string
  colorScheme?: string
  dateFormat?: string
  defaultFilterValue?: Option
  filterOptions?: Option[]
  hasFilter?: boolean
  hasDateRange?: boolean
  iconColor?: string
  minDate?: Date
  maxDate?: Date
  onChange?: (date: DateRange) => void
  onFilterChange?: (value: string) => void
}

export const CbMobileFilterDateRange: FC<CbMobileFilterDateRangeProps> = ({
  colorScheme = 'whiteAlpha',
  dateFormat,
  defaultFilterValue,
  filterOptions,
  hasFilter = true,
  hasDateRange = true,
  iconColor = 'white',
  minDate,
  maxDate,
  onChange,
  onFilterChange = () => {},
}) => {
  const [showDate, setShowDate] = useState(false)
  const [showFilter, setShowFilter] = useState(false)

  const handleDateClick = (): void => {
    setShowDate(!showDate)
    setShowFilter(false)
  }

  const handleFilterClick = (): void => {
    setShowFilter(!showFilter)
    setShowDate(false)
  }

  return (
    <Box width="100%">
      <Flex justifyContent="flex-end">
        {hasFilter && (
          <IconButton
            _focus={{ outline: 'none' }}
            borderRadius={100}
            borderWidth="1px"
            borderColor={iconColor}
            colorScheme={showFilter ? 'gray' : colorScheme}
            aria-label="Toggle Filer"
            onClick={handleFilterClick}
          >
            {showFilter ? <Close /> : <FilterOutline />}
          </IconButton>
        )}
        {hasDateRange && (
          <IconButton
            _focus={{ outline: 'none' }}
            borderRadius={100}
            borderWidth="1px"
            borderColor={iconColor}
            colorScheme={showDate ? 'gray' : colorScheme}
            aria-label="Toggle Date Picker"
            marginLeft={2}
            onClick={handleDateClick}
          >
            {showDate ? <Close /> : <Calendar />}
          </IconButton>
        )}
      </Flex>
      <Box
        display={showDate ? 'block' : 'none'}
        marginTop={3}
        paddingRight="44px"
      >
        <CbDateRangePicker
          color={iconColor}
          borderColor={iconColor}
          dateFormat={dateFormat}
          iconColor={iconColor}
          minDate={minDate}
          maxDate={maxDate}
          variant="cb-outlineWhite"
          width="100%"
          _focus={{ borderColor: 'white' }}
          onChange={onChange}
        />
      </Box>
      {filterOptions && showFilter && (
        <Box marginTop={3}>
          <CbSelect
            borderColor={iconColor}
            color={iconColor}
            getValueFromOption={filter => filter.type}
            onChange={onFilterChange}
            options={filterOptions}
            value={defaultFilterValue}
            variant="cb-input-outlineWhite"
            width="100%"
          />
        </Box>
      )}
    </Box>
  )
}
