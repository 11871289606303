import { FC, useState } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import {
  Flex,
  Button,
  InputGroup,
  InputProps,
  InputRightElement,
  InputLeftElement,
} from '@chakra-ui/react'
import ChevronDown from 'ui/components/Icons/ChevronDown'
import Calendar from '../Icons/Calendar'
import { CbInput } from '../Inputs/CbInput'
import { ICON_BY_NAME } from '../Icons/Icon'

export type DateRange = [startDate: Date, endDate: Date]

export interface CbDateRangePickerProps
  extends Partial<Omit<InputProps, 'onChange'>> {
  values?: DateRange | []
  dateFormat?: string
  iconColor?: string
  minDate?: Date
  maxDate?: Date
  variant?: string
  onChange?: (date: Date[]) => void
  onClear?: () => void
}

export const CbDateRangePicker: FC<CbDateRangePickerProps> = ({
  values = [moment().subtract(30, 'days').toDate(), moment().toDate()],
  dateFormat = 'MMM D',
  iconColor = 'cloud.600',
  minDate,
  maxDate,
  variant = 'cb-base',
  onChange,
  onClear,
  ...props
}) => {
  const [shouldCloseOnSelect, setShouldCloseOnSelect] = useState(false)
  const [startDate, endDate] = values

  const formatDateRange = (): string => {
    const startFormatted = moment(startDate).format(dateFormat)
    const endFormatted = moment(endDate).format(dateFormat)
    if (startDate && endDate) return `${startFormatted} - ${endFormatted}`
    if (startDate) return startFormatted
    return 'Date Range'
  }

  const handleChange = (dateRange: DateRange): void => {
    if (dateRange.length > 1) {
      setShouldCloseOnSelect(true)
      onChange?.(dateRange)
    }
  }

  return (
    <Flex position="relative">
      <DatePicker
        onInputClick={() => setShouldCloseOnSelect(false)}
        onClickOutside={() => setShouldCloseOnSelect(true)}
        shouldCloseOnSelect={shouldCloseOnSelect}
        selected={startDate}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        onChange={handleChange}
        customInput={
          <InputGroup cursor="pointer">
            <CbInput
              borderRadius={50}
              fontWeight="600"
              paddingLeft={12}
              cursor="pointer"
              readOnly
              value={formatDateRange()}
              variant={variant}
              {...props}
            />
            <InputLeftElement
              sx={{ h: 'var(--chakra-sizes-10)', w: 'var(--chakra-sizes-10)' }}
              children={
                <Calendar color={iconColor} marginLeft={4} zIndex="0" />
              }
            />
            <InputRightElement
              children={
                <ChevronDown
                  color={iconColor}
                  marginRight={1}
                  width="10px"
                  zIndex="0"
                />
              }
              sx={{ h: 'var(--chakra-sizes-10)', w: 'var(--chakra-sizes-10)' }}
            />
          </InputGroup>
        }
      />
      {startDate && (
        <Button
          position="absolute"
          right="-44px"
          backgroundColor="transparent"
          borderRadius="50%"
          color={iconColor}
          onClick={onClear}
          height="40px"
          width="40px"
          marginLeft={2}
          _hover={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
          _focus={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
        >
          <ICON_BY_NAME.close height="14px" width="14px" />
        </Button>
      )}
    </Flex>
  )
}
